import i18next from 'i18next'
import { KehilaLanguageEnum } from '~/generated/api'

export const kehilaLangLocaleMap = {
  [KehilaLanguageEnum.Hebrew]: 'he',
  [KehilaLanguageEnum.Arabic]: 'ar',
  [KehilaLanguageEnum.English]: 'en',
} as const

export async function getFixedT(ns: string) {
  await i18next.loadNamespaces(ns)
  return i18next.getFixedT(null, ns)
}
